import { Component } from "@angular/core";

@Component({
    selector: "intuto-terms",
    template: `
<div class="terms">
    <a href="https://www.intuto.com/terms" target="_blank">Terms of Service</a>
    <a href="https://www.intuto.com/privacy" target="_blank">Privacy Policy</a>
</div>`,
    styleUrl: "./intuto-terms.scss"
})
export class IntutoTermsComponent { }
