<ng-container *ngIf="state$ | async as state">
    <ng-container *ngIf="state.settings">
        <div class="auth-card-section"
            *ngIf="state.settings.providerCount > 0 && state.settings.enableLocalLogin">
            <p>OR</p>
        </div>

        <div class="auth-card-section" *ngIf="state.settings.providerCount > 0">
            <div class="form-group" *ngFor="let externalProvider of state.settings.visibleExternalProviders">
                <a class="form-control btn btn-primary"
                    [href]="'/externalLogin/challenge?scheme=' + externalProvider.authenticationScheme + '&amp;returnUrl=' + (form.controls.returnUrl.value | encodeURIComponent)">
                    <span>Continue with {{ externalProvider.displayName }}</span>
                    <i class="fas fa-external-link-alt"></i>
                </a>
            </div>
            <div class="form-group" *ngIf="state.settings.getSocialProvider('Google')">
                <a class="form-control btn btn-light"
                    [href]="'/externalLogin/challenge?scheme=' + state.settings.getSocialProvider('Google').authenticationScheme + '&amp;returnUrl=' + (form.controls.returnUrl.value | encodeURIComponent)">
                    <i class="fab fa-google"></i>
                    <span>Continue with Google</span>
                </a>
            </div>
            <div class="form-group" *ngIf="state.settings.getSocialProvider('Facebook')">
                <a class="form-control btn btn-light"
                    [href]="'/externalLogin/challenge?scheme=' + state.settings.getSocialProvider('Facebook').authenticationScheme + '&amp;returnUrl=' + (form.controls.returnUrl.value | encodeURIComponent)">
                    <i class="fab fa-facebook"></i>
                    <span>Continue with Facebook</span>
                </a>
            </div>
        </div>

        <div class="alert alert-warning" *ngIf="!state.settings.enableLocalLogin && state.settings.visibleExternalProviders.length === 0">
            <strong>Invalid log in request</strong>
            <p>There are no log in schemes configured for this client.</p>
        </div>
    </ng-container>
</ng-container>
