import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpValidationProblemDetails } from "../../../../shared/classes/http-validation-problem-details";
import { UserService } from '../../../../shared/services/user.service';
import { ErrorVisibility } from '../classes/error-visibility';
import { LoginService } from '../services/login.service';
import * as fromLogin from '../store/login.actions';

@Injectable()
export class LoginEffects {
    loadLoginState$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromLogin.loadLoginState),
            mergeMap(() => this.activatedRoute.queryParams.pipe(
                map(
                    params => {
                        let queryParams = null;
                        let returnUrl = "/"; // default

                        // get values from querystring, override with post result
                        const returnUrlKey = Object.keys(params).find(key => /returnUrl/i.test(key));
                        if (returnUrlKey) {
                            returnUrl = params[returnUrlKey];
                        }

                        queryParams = { returnUrl };

                        const usernameKey = Object.keys(params).find(key => /(username|email)/i.test(key));
                        if (usernameKey) {
                            queryParams = queryParams
                                ? { ...queryParams, username: params[usernameKey] }
                                : { username: params[usernameKey] };
                        }

                        return fromLogin.queryParamsLoaded({ queryParams });
                    },
                    catchError(() => EMPTY)
                )
            ))
        );
    });

    loadSettings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromLogin.queryParamsLoaded),
            mergeMap(action => this.loginService.loadSettings(action.queryParams).pipe(
                map(settings =>
                    fromLogin.loginSettingsLoaded({ settings }),
                    catchError(() => EMPTY)
                )
            ))
        );
    });

    settingsLoaded$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromLogin.loginSettingsLoaded),
            map(action => {
                let errorMessage = undefined;

                if (action.settings.validationProblemDetails) {
                    errorMessage = action.settings.validationProblemDetails.firstError();
                }

                return fromLogin.loginFailure({ error: errorMessage });
            })
        );
    });

    login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromLogin.login),
            mergeMap(action => {
                return this.loginService.login(action.request).pipe(
                    map(result =>
                        fromLogin.loginSuccess({ result })
                    ),
                    catchError((errorResponse: HttpErrorResponse) => {
                        let errorMessage = "Unknown login error";
                        // Validation error, therefor we'll extract the error message to show in the UI
                        if (errorResponse.status === 400 &&
                            errorResponse.error.hasOwnProperty("type") &&
                            errorResponse.error.hasOwnProperty("errors")) {
                            const validationProblemDetails = new HttpValidationProblemDetails(errorResponse.error);
                            errorMessage = validationProblemDetails.firstError();
                        }
                        return of(fromLogin.loginFailure({ error: errorMessage }));
                    })
                );
            })
        );
    });

    setFormIsSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromLogin.login),
            map(() => fromLogin.formSubmittedStatusChanged({ formIsSubmitted: true }))
        );
    });

    resetFormIsSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                fromLogin.loginSuccess,
                fromLogin.loginFailure
            ),
            delay(1000),
            map(() => fromLogin.formSubmittedStatusChanged({ formIsSubmitted: false }))
        );
    });
    
    setStateType$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromLogin.setState),
            map(action => fromLogin.stateTypeSet({ stateType: action.stateType }))
        );
    });

    checkEmail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromLogin.checkEmail),
            switchMap(action => this.userService.checkEmail(action.email).pipe(
                map(result =>
                    fromLogin.emailChecked({ emailCheckResult: result }),
                    catchError(() => EMPTY)
                )
            ))
        );
    });

    toggleErrorVisibility$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromLogin.toggleErrorVisibility),
            map(action =>
                fromLogin.errorVisibilityChanged(
                    { errorVisibility: { username: action.username, password: action.password } as ErrorVisibility }
                ))
        );
    });

    markFormAsReady$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromLogin.markFormAsReady),
            map(() => fromLogin.formReadyStatusChanged({ formIsReady: true }))
        );
    });

    unmarkFormAsReady$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromLogin.unmarkFormAsReady),
            map(() => fromLogin.formReadyStatusChanged({ formIsReady: false }))
        );
    });

    constructor(
        private actions$: Actions,
        private loginService: LoginService,
        private userService: UserService,
        private activatedRoute: ActivatedRoute
    ) { }
}
