<ng-container *ngIf="state$ | async as state">
    <div class="container" *ngIf="state.settings && !state.settings.redirectUrl">
        <div class="row">
            <div class="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-xs-10 offset-xs-1">
                <div class="auth-card">
                    <div class="auth-card-section">
                        <intuto-logo></intuto-logo>
                    </div>

                    <div class="auth-card-section">
                        <h1 [ngSwitch]="state.stateType">
                            <span *ngSwitchCase="LoginStateType.EnterPassword">Enter your password</span>
                            <span *ngSwitchCase="LoginStateType.CreateLocalAuth">No Password</span>
                            <span *ngSwitchDefault>{{ state.settings?.allowSiteRegister ? 'Register an account' : 'Log in to your account' }}</span>
                        </h1>
                        <p *ngIf="state.stateType === LoginStateType.CreateLocalAuth">
                            You may have logged in with an external account last time. Create a password for your account, or go back and try another log-in method.
                        </p>
                    </div>

                    <div class="auth-card-section" [hidden]="!(state$ | async).settings?.enableLocalLogin">
                        <form
                            [formGroup]="form"
                            novalidate
                            #loginForm
                            (keydown.enter)="isFormReadyToSubmit($event)"
                            (ngSubmit)="onSubmit()">
                            <fieldset>
                                <div *ngIf="appSettings.siteNotification.enabled && (state$ | async).stateType !== LoginStateType.CreateLocalAuth">
                                    <site-notification></site-notification>
                                </div>

                                <ng-container *ngIf="errorMessage$ | async as errorMessage">
                                    <div class="alert alert-danger shake">
                                        {{ errorMessage }}
                                    </div>
                                </ng-container>

                                <username-input [form]="form" [hidden]="(state$ | async).stateType !== LoginStateType.EnterUsername"></username-input>
                                <password-input [form]="form" [hidden]="(state$ | async).stateType !== LoginStateType.EnterPassword"></password-input>
                                <local-auth-creation [form]="form" [hidden]="(state$ | async).stateType !== LoginStateType.CreateLocalAuth"></local-auth-creation>

                                <input name="ReturnUrl" formControlName="returnUrl" type="hidden" />
                                <input name="__RequestVerificationToken" type="hidden" formControlName="antiforgeryToken" />
                            </fieldset>
                            <h-captcha
                                *ngIf="appSettings.captcha.captchaEnabled"
                                #hCaptchaControl
                                (verified)="onCaptchaVerified($event)"
                                (error)="onCaptchaError($event)"
                                [form]="form"
                                size="invisible">
                            </h-captcha>
                        </form>
                    </div>

                    <ng-container>
                        <div class="auth-card-section" *ngIf="!state.settings?.enableLocalLogin">
                            <site-notification></site-notification>
                        </div>

                        <div class="auth-card-section">
                            <external-providers *ngIf="state.stateType === LoginStateType.EnterUsername" [form]="form"></external-providers>
                        </div>

                        <div class="auth-card-section" *ngIf="state.settings?.enableLocalLogin && state.stateType === LoginStateType.EnterUsername">
                            <div class="form-group">
                                <a id="forgot-password-footer-link"
                                   [href]="'/account/forgotpassword?email=' + ((form.controls.username.value ?? '') | encodeURIComponent) + '&amp;returnUrl=' + (form.controls.returnUrl.value | encodeURIComponent)">
                                    Having trouble logging in?
                                </a>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <intuto-terms></intuto-terms>
            </div>
        </div>
    </div>
</ng-container>