<div class="form-group">
    <div class="input-validation" *ngIf="emailCheckInProgress">
        <i class="fa fa-spinner fa-pulse fa-fw"></i>
    </div>
    <input
        formControlName="username"
        type="email"
        #usernameInput
        name="Username"
        class="form-control"
        autocomplete="username"
        placeholder="Enter your email address"
        (keyup.enter)="checkEmail()" />
    <div class="invalid-feedback" *ngIf="(showValidationErrors$ | async) && form.controls.username.invalid && (form.controls.username.dirty || form.controls.username.touched)">
        <div *ngIf="form.controls.username.errors?.required || form.controls.username.errors?.empty">Email address is required.</div>
        <div *ngIf="form.controls.username.errors?.email">That email address doesn't look valid.</div>
        <div *ngIf="form.controls.username.errors?.notexist">Oops! That email address doesn't exist.</div>
    </div>
</div>
<div class="form-group">
    <button
        type="button"
        class="form-control btn btn-primary"
        (click)="checkEmail()"
        (keyup.enter)="checkEmail()">
        <span>Continue</span>
        <i class="fas fa-arrow-right"></i>
    </button>
</div>