import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ArraySortPipe } from "../pipes/array-sort.pipe";
import { CastAsPipe } from "../pipes/cast-as.pipe";
import { EncodeURIComponentPipe } from "../pipes/encode-uri-component.pipe";
import { SafeHtmlPipe } from "../pipes/safe-html.pipe";
import { ErrorDialogModal } from "./error-dialog/error-dialog.modal";
import { IntutoLogoComponent } from "./intuto-logo/intuto-logo.component";
import { IntutoTermsComponent } from "./intuto-terms/intuto-terms.component";

@NgModule({
    declarations: [
        ArraySortPipe,
        SafeHtmlPipe,
        CastAsPipe,
        EncodeURIComponentPipe,
        ErrorDialogModal
    ],
    imports: [CommonModule],
    exports: [
        ArraySortPipe,
        SafeHtmlPipe,
        CastAsPipe,
        EncodeURIComponentPipe,
        ErrorDialogModal
    ]
})
export class SharedComponentModule { }