import { ExternalProvider } from "./external-provider";
import { HttpValidationProblemDetails } from "../../../../shared/classes/http-validation-problem-details";

export class LoginSettings {
    allowRememberLogin: boolean;
    enableLocalLogin: boolean;
    allowSiteRegister: boolean;
    socialExternalProviders: ExternalProvider[];
    visibleExternalProviders: ExternalProvider[];
    isExternalLoginOnly: boolean;
    externalLoginScheme: string;
    redirectUrl: string;
    antiforgeryToken: string;
    validationProblemDetails: HttpValidationProblemDetails;

    getSocialProvider(key: string) {
        return this.socialExternalProviders.find(p => p.displayName === key);
    }

    get providerCount(): number {
        return this.socialExternalProviders.length + this.visibleExternalProviders.length;
    }

    public constructor(init?: Partial<LoginSettings>) {
        if (init.validationProblemDetails) {
            init.validationProblemDetails = new HttpValidationProblemDetails(init.validationProblemDetails);
        }

        Object.assign(this, init);
    }
}
