import { NgModule } from "@angular/core";
import { IntutoLogoComponent } from "./intuto-logo/intuto-logo.component";
import { IntutoTermsComponent } from "./intuto-terms/intuto-terms.component";

@NgModule({
    declarations: [
        IntutoLogoComponent,
        IntutoTermsComponent
    ],
    exports: [
        IntutoLogoComponent,
        IntutoTermsComponent
    ]
})
export class CommonComponentModule { }