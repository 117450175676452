import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";

const emailRegex: RegExp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

@Injectable()
export class ValidationService {
    // The default email validator in Angular forms doesn't work correctly with input fields of type 'email', so a custom validator is necessary.
    email() {
        return (formControl: AbstractControl<any, any>): ValidationErrors | null => {
            const invalidResult = { email: true };
            const validResult = null;

            if (!formControl.value) {
                return validResult;
            }

            const email = formControl.value.match(emailRegex);

            return email ? validResult : invalidResult;
        }
    }

    notEmpty() {
        return (formControl: AbstractControl<any, any>): ValidationErrors | null => {
            const invalidResult = { empty: true };
            const validResult = null;

            return (
                !formControl.value || 
                (typeof formControl.value === "string" && formControl.value.trim().length === 0)
            )
                ? invalidResult
                : validResult;
        }
    }

    invalidate(key: string) {
        return (formGroup: FormGroup): ValidationErrors | null => {
            let invalidResult = {};
            invalidResult[key] = true;
            return invalidResult;
        }
    }

    constructor() { }
}